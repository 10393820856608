import React, { useState } from "react";
import Text from "@amzn/meridian/text";
import Select, { SelectOption } from "@amzn/meridian/select";
import Column from "@amzn/meridian/column";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import { createSearchRegExp } from "src/utils/searchUtils";
import { setSelectedFilterOptions } from "src/store/placementOverviewPageSlice";

const multipleDaysInCategoryDropdown = () => {
  const dispatch = useDispatch();

  const { availableFilterOptions, selectedFilterOptions } = useAppSelector((state) => state.placementOverviewPage);
  const availableDaysInCategory = availableFilterOptions.availableDaysInCategory;

  const [searchQuery, setSearchQuery] = useState();
  const searchRegExp = createSearchRegExp(searchQuery);

  const matchedDaysInCategory = availableDaysInCategory.filter(
    (daysInCategory: number) => !searchQuery || searchRegExp.test("" + daysInCategory)
  );

  return (
    <Column spacing="100">
      <Text>
        Dwell Time / Time Expired / Time in Placement / Closed Time{" "}
        <span className='placement-overview-advanced-filter__days'>(days)</span>
      </Text>
      <Select
        size='small'
        value={selectedFilterOptions.daysInCategory}
        onChange={(newValue) => {
          dispatch(setSelectedFilterOptions({ ...selectedFilterOptions, daysInCategory: newValue }));
        }}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        placeholder='Enter value...'
      >
        {matchedDaysInCategory.map((daysInCategory: number) => (
          <SelectOption label={daysInCategory} value={daysInCategory} key={daysInCategory} />
        ))}
        {!matchedDaysInCategory.length ? (
          <Column alignmentVertical='center' spacing='300' spacingInset='500'>
            <Text alignment='center'>No results</Text>
          </Column>
        ) : null}
      </Select>
    </Column>
  );
};

export default multipleDaysInCategoryDropdown;
