import React from "react";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import DatePicker from "@amzn/meridian/date-picker";
import { useAppSelector } from "src/store/store";
import { setSelectedFilterOptions } from "src/store/placementOverviewPageSlice";
import { useDispatch } from "react-redux";
import moment from "moment";

const startAndEndDateInput = () => {
  const dispatch = useDispatch();
  const { selectedFilterOptions } = useAppSelector((state) => state.placementOverviewPage);

  return (
    <Column spacing="400">
      <Column spacing="100">
        <Text>Placement Start Date</Text>
        <DatePicker
          value={moment(selectedFilterOptions.placementStartDate).format("YYYY-MM-DD")}
          onChange={(newValue) => {
            dispatch(setSelectedFilterOptions({ ...selectedFilterOptions, placementStartDate: moment(newValue).format("MM/DD/YYYY") }));
          }}
          placeholder='MM/DD/YYYY'
        />
      </Column>
      <Column spacing="100">
        <Text>Placement End Date</Text>
        <DatePicker
          value={moment(selectedFilterOptions.placementEndDate).format("YYYY-MM-DD")}
          onChange={(newValue) => {
            dispatch(setSelectedFilterOptions({ ...selectedFilterOptions, placementEndDate: moment(newValue).format("MM/DD/YYYY") }));
          }}
          placeholder='MM/DD/YYYY'
        />
      </Column>
    </Column>
  );
};

export default startAndEndDateInput;
