import React, { useEffect, useState } from "react";
import Heading from "@amzn/meridian/heading";
import PlacementOverviewTable from "./placementOverviewTable/placementOverviewTable";
import AdvancedFilterSheet from "./advancedFilter/advancedFilterSheet/advancedFilterSheet";
import AppliedFilterTags from "./appliedFilterTags/appliedFilterTags";
import TabGroup from "./tabGroup/tabGroup";
import Box from "@amzn/meridian/box";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  getOpenPlacementsByRequesterLogin,
  getOpenAndClosedPlacementsBySiteId,
  setIsDaliUser,
  setSearchField,
  setIsAdvancedFilterSheetOpen,
  setSelectedFilterOptions,
  setPerPage,
  setCurrentPage,
} from "src/store/placementOverviewPageSlice";
import SearchField from "@amzn/meridian/search-field";
import Icon from "@amzn/meridian/icon";
import filterTokens from "@amzn/meridian-tokens/base/icon/filter";
import Row from "@amzn/meridian/row";
import "./placementsOverview.scss";
import Column from "@amzn/meridian/column";
import {
  setIsSiteSelectionDisabled,
  setSelectedSite,
} from "src/store/siteSlice";
import Button from "@amzn/meridian/button";
import { useNavigate, useParams } from "react-router-dom";
import { getUser, setPreferredSite } from "src/store/userSlice";
import PaginationWithDropdown from "src/components/shared/paginationWithDropdown/paginationWithDropdown";

const placementsOverview = () => {
  const dispatch = useAppDispatch();
  let { site, tab } = useParams();
  const [currentTab, setCurrentTab] = useState(tab || "pendingPlacements");
  const {
    searchField,
    isUserDaliUser,
    appliedFilterOptions,
    currentPage,
    totalPages,
    perPage,
  } = useAppSelector((state) => state.placementOverviewPage);
  const navigate = useNavigate();
  let { userLoading, alias } = useAppSelector((state) => state.user);
  const { selectedSite, allSites, allowedSites } = useAppSelector((state) => state.sites);

  const isTabValid = (tab: string) => {
    return [
      "pendingPlacements",
      "activePlacements",
      "expiredPlacements",
      "closedPlacements",
      "transferredInPlacements"
    ].includes(tab);
  };

  useEffect(() => {
    if (userLoading === "fulfilled" && selectedSite.site) {
      if (selectedSite.site && selectedSite.site !== site) {
        site = selectedSite.site;
        navigate(`/placementsOverview/${site}/pendingPlacements`);
      }
      if (!allowedSites.map(allowedSite => allowedSite.site).includes(site!)) {
        navigate("unauthorized");
      }
      /**
       * We would normally do this
       * const isUserDaliUser = isDaliUser(departmentCode);
       * but due to this issue, we are temporarily going with
       * a single experience for both DALI and non DALI users
       * https://issues.amazon.com/P70918518
       */
      const isUserDaliUser = false;
      if (isUserDaliUser) {
        dispatch(getOpenPlacementsByRequesterLogin(alias));
        dispatch(setIsSiteSelectionDisabled(true));
      } else {
        dispatch(getOpenAndClosedPlacementsBySiteId(selectedSite.id));
      }
      dispatch(setIsDaliUser(isUserDaliUser));
    }

    return () => {
      dispatch(setIsSiteSelectionDisabled(false));
    };
  }, [userLoading, selectedSite]);

  useEffect(() => {
    if (site && allSites.length > 0) {
      const siteObj = allSites.filter((s) => s.site === site)[0];
      if (siteObj) {
        dispatch(setSelectedSite(siteObj));
        dispatch(setPreferredSite(siteObj.id));

        if (tab && currentTab !== tab && isTabValid(tab)) {
          navigate(`/placementsOverview/${siteObj.site}/${currentTab}`);
          dispatch(setCurrentPage(1));
        }
        if (tab && !isTabValid(tab)) {
          setCurrentTab("pendingPlacements");
          navigate(`/placementsOverview/${siteObj.site}/pendingPlacements`);
          dispatch(setCurrentPage(1));
        }
      }
    }
  }, [tab, site, allSites, currentTab]);

  return (
    <div className="placements-overview">
      <Column spacingInset={"none none 400 none"}>
        <Heading level={1}>
          {isUserDaliUser
            ? `My Placements`
            : `Placements Overview for ${selectedSite.site}`}
        </Heading>
        <Row>
          <TabGroup currentTab={currentTab} setCurrentTab={setCurrentTab} />
          <Button
            type="primary"
            onClick={() => {
              navigate("/newJMR");
            }}
          >
            New Job Match Report
          </Button>
        </Row>

        <Box type="fill" spacingInset="300">
          <Row widths={["grid-3", "fit"]}>
            <SearchField
              value={searchField}
              onChange={(newSearchField) =>
                dispatch(setSearchField(newSearchField))
              }
              placeholder="Search for..."
              onSubmit={() => {}}
            />
            {currentTab !== "transferredInPlacements" && <div
              className="placements-overview__filter-icon"
              onClick={() => {
                dispatch(setIsAdvancedFilterSheetOpen(true));
                dispatch(setSelectedFilterOptions(appliedFilterOptions));
              }}
            >
              <Icon tokens={filterTokens}></Icon>
            </div>}
          </Row>
        </Box>
        <AppliedFilterTags />
        <PlacementOverviewTable currentTab={currentTab} />
        <AdvancedFilterSheet />
        <PaginationWithDropdown
          currentPage={currentPage}
          totalPages={totalPages}
          perPage={perPage}
          setPerPage={(newPerPage) => dispatch(setPerPage(newPerPage))}
          setCurrentPage={(newPage) => dispatch(setCurrentPage(newPage))}
        />
      </Column>
    </div>
  );
};

export default placementsOverview;