import React, { useEffect } from "react";
import Modal from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  addOrEditSiteCapacity,
  setIsCreateModalOpen,
  setSiteCapacityFormData,
  setSiteCapacityFormErrors,
  TableDataRow,
} from "src/store/updateSiteCapacitySlice";
import { useForm } from "src/hooks/useForm";
import SiteCapacitysFormFields from "../siteCapacityFormFields/siteCapacityFormFields";

const createNewSiteCapacity = () => {
  const dispatch = useAppDispatch();
  const { isCreateModalOpen, siteCapacityFormData } = useAppSelector(
    (state) => state.updateSiteCapacityPage
  );
  const { validateForm, resetForm, setFormValues, formData } =
    useForm<TableDataRow>({
      validations: {
        shifts: {
          custom: {
            isValid: () => {
              const shiftErrors: any = { shifts: {} };
              const areShiftsValid = siteCapacityFormData?.shifts?.map(
                (shift) => {
                  const isError = !Number.isInteger(+shift?.slots);
                  shiftErrors.shifts[shift?.shiftCode] = {
                    error: isError,
                    errorMessage: isError ? "Slots must be an integer" : "",
                  };
                  return !isError;
                }
              );
              dispatch(setSiteCapacityFormErrors(shiftErrors));
              return areShiftsValid?.every((val) => val) ? true : false;
            },
            message: "Slots must be an integer",
          },
        },
      },
    });

  useEffect(() => {
    if (siteCapacityFormData) {
      setFormValues(siteCapacityFormData as TableDataRow);
    }
  }, [siteCapacityFormData]);

  const onSave = () => {
    if (validateForm()) {
      dispatch(addOrEditSiteCapacity(formData));
      dispatch(setIsCreateModalOpen(false));
      dispatch(setSiteCapacityFormData(undefined));
      resetForm();
    }
  };

  const onCancel = () => {
    dispatch(setIsCreateModalOpen(false));
    dispatch(setSiteCapacityFormData(undefined));
    resetForm();
  };

  return (
    <Modal
      open={isCreateModalOpen}
      onClose={onCancel}
      closeLabel="Close"
      aria-describedby="modal-description"
      width="480px"
      scrollContainer="modal"
      bodySpacingInset={"500 200 500 500"}
    >
      <Text type="h300">Edit</Text>
      <SiteCapacitysFormFields />
      <Row alignmentHorizontal="end" widths={["fill", "80px", "66px"]}>
        <div></div>
        <Button
          className="update-site-capacity__modal__cancel-btn"
          type="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className="update-site-capacity__modal__submit-btn"
          onClick={onSave}
        >
          Save
        </Button>
      </Row>
    </Modal>
  );
};

export default createNewSiteCapacity;
