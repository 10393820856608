import React from "react";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Icon from "@amzn/meridian/icon";
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete";
import Text from "@amzn/meridian/text";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import { setMultipleSitesDropdownValues } from "src/store/defaultSettingsPageSlice";

const selectedSiteList = () => {
  const dispatch = useDispatch();
  const { allowedSites } = useAppSelector((state) => state.sites);
  const { pageDisabled, multipleSitesDropdownValues } = useAppSelector(
    (state) => state.defaultSettingsPage
  );

  /**
   * Delete site row given id
   */
  const deleteSiteRow = (siteId: number) => {
    if (!pageDisabled) {
      dispatch(
        setMultipleSitesDropdownValues(
          multipleSitesDropdownValues.filter((item) => item !== siteId)
        )
      );
    }
  };

  return (
    <Row widths={["grid-6"]}>
      <Box type="fill" height={200} overflowY={"auto"} spacingInset="300">
        <Column spacing={"small"}>
          {[...multipleSitesDropdownValues].reverse().map((siteId, index) => (
            <Row
              key={index}
              className="default-settings__content__site-row"
              widths={["grid-11", "grid-1"]}
            >
              <Text className="default-settings__content__site-row__text">
                {allowedSites?.filter((item) => item.id === siteId)[0]?.site ||
                  ""}
              </Text>
              {!pageDisabled && (
                <div
                  className="default-settings__content__site-row__delete-icon"
                  onClick={() => deleteSiteRow(siteId)}
                >
                  <Icon tokens={deleteTokens} />
                </div>
              )}
            </Row>
          ))}
        </Column>
      </Box>
    </Row>
  );
};

export default selectedSiteList;
