import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { TableSortDirection } from "@amzn/meridian/table/table";
import UncontrolledExpandableRow from "src/components/shared/uncontrolledExpandableRow/uncontrolledExpandableRow";
import Tag from "@amzn/meridian/tag";
import { filterSortAndPaginateTableData } from "src/utils/tableUtils";
import { SortStrategy } from "src/utils/sortStrategies";
import { setTotalPages, setCurrentPage } from "src/store/placementOverviewPageSlice";
import { TableDataRow } from "src/services/placementOverview/types/tableDataRow";
import { AllocatedJobShift } from "src/services/placementOverview/types/allocatedJobShift";
import { useNavigate } from "react-router-dom";
import { COUNTRY_ID_MAPPING } from "src/config/CountryIdMapping";
import { PLACEMENT_DETAILS_BASEURL } from "src/constants/Routes";
import { ClosenessToExpiration } from "src/services/placementOverview/types/closenessToExpiration";

const placementsOverviewTable = ({ currentTab }: { currentTab: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    activePlacementsTableData,
    pendingPlacementsTableData,
    expiredPlacementsTableData,
    closedPlacementsTableData,
    transferredInPlacementsTableData,
    isUserDaliUser,
    searchField,
    currentPage,
    perPage,
  } = useAppSelector((state) => state.placementOverviewPage);
  const { selectedSite } = useAppSelector((state) => state.sites);

  const [sortColumn, setSortColumn] = useState("associateNameAndAlias");
  const [sortStrategy, setSortStrategy] = useState(SortStrategy.DEFAULT);
  const [sortDirection, setSortDirection] = useState<TableSortDirection>("ascending");
  const [daysInCategoryTableHeader, setDaysInCategoryTableHeader] = useState("");

  const onSort = useCallback(({ sortColumn, sortDirection } : {sortColumn: string, sortDirection: TableSortDirection}) => {
    const sortStrategy = evaluateSortStrategy(sortColumn);
    setSortStrategy(sortStrategy);
    setSortDirection(sortDirection);
    setSortColumn(sortColumn);
  }, []);

  // choose sort strategy based on column type.
  // default is sort by string
  const evaluateSortStrategy = (sortColumn: string) => {
    const columnStrategies = {
      placementStartDate: SortStrategy.DATE,
      placementEndDate: SortStrategy.DATE,
      closedAtDate: SortStrategy.DATE,
      daysInCategory: SortStrategy.NUMBER,
      totalAccommodatedDaysByAssociate: SortStrategy.NUMBER,
    };

    return columnStrategies[sortColumn as keyof typeof columnStrategies] || SortStrategy.DEFAULT;
  };

  const [tableData, setTableData] = useState<TableDataRow[]>([]);
  const [sortedTableData, setSortedTableData] = useState<TableDataRow[]>([]);

  // show 180 day counter if not in closed placements and not in a canada site
  const canShow180DayCounter = () => {
    return currentTab !== "closedPlacements" && selectedSite.countryId !== COUNTRY_ID_MAPPING.CANADA_COUNTRY_ID;
  };

  useEffect(() => {
    switch (currentTab) {
      case "pendingPlacements":
        setTableData(pendingPlacementsTableData);
        setDaysInCategoryTableHeader("Dwell Time");
        break;
      case "expiredPlacements":
        setTableData(expiredPlacementsTableData);
        setDaysInCategoryTableHeader("Time Expired");
        break;
      case "activePlacements":
        setTableData(activePlacementsTableData);
        setDaysInCategoryTableHeader("Time in Placement");
        break;
      case "closedPlacements":
        setTableData(closedPlacementsTableData);
        setDaysInCategoryTableHeader("Time Closed");
        break;
      case "transferredInPlacements":
        setTableData(transferredInPlacementsTableData);
        break;
    }
  }, [currentTab, activePlacementsTableData, closedPlacementsTableData]);

  useEffect(() => {
    const jobsNotDisplayed = ((row: any) => !row.jobsDisplayed)
    const [paginatedData, totalPages] = filterSortAndPaginateTableData(
      tableData,
      searchField,
      sortColumn,
      sortDirection,
      currentPage,
      ["id"],
      perPage,
      sortStrategy,
      [["jobs", jobsNotDisplayed], ["jobTypes", jobsNotDisplayed], ["shiftCodes", jobsNotDisplayed]]
    );
    setSortedTableData(paginatedData as any[]);
    dispatch(setTotalPages(totalPages));
  }, [sortColumn, sortDirection, tableData, searchField, currentPage, perPage]);

  useEffect(() => {
    // go back to first page when filter/sort
    dispatch(setCurrentPage(1));
  }, [searchField, sortColumn, sortDirection, perPage]);

  const tableHeaders = [
    { sortColumn: "associateNameOrAlias", label: "Associate Name" },
    { sortColumn: "jobs", label: "Job" },
    { sortColumn: "jobTypes", label: "Job Type" },
    { sortColumn: "site", label: "Site", condition: isUserDaliUser },
    { sortColumn: "placementStartDate", label: "Placement Start Date" },
    { sortColumn: "placementEndDate", label: "Placement End Date" },
    {
      sortColumn: "closedAtDate",
      label: "Closed At Date",
      condition: currentTab === "closedPlacements",
    },
    {
      sortColumn: "daysInCategory",
      label: `${daysInCategoryTableHeader} (days)`,
    },
    { sortColumn: "placementType", label: "Placement Type" },
    {
      sortColumn: "caseId",
      label: "Case Id",
    },
    { sortColumn: "status", label: "Status" },
    {
      sortColumn: "totalAccommodatedDaysByAssociate",
      label: "Total Days in Placement(s)",
      condition: canShow180DayCounter(),
      id: "placementOverview__table__totalDaysInPlacement",
    },
  ];

  const renderTableHeaders = () => {
    if(currentTab !== "transferredInPlacements") {
      return (
        <TableRow>
          {tableHeaders.map((header) =>
            header.condition !== undefined ? (
              header.condition && (
                <TableCell sortColumn={header.sortColumn} id={header.id}>
                  {header.label}
                </TableCell>
              )
            ) : (
              <TableCell sortColumn={header.sortColumn} id={header.id}>
                {header.label}
              </TableCell>
            )
          )}
        </TableRow>
      );
    } else {
      return getTransferredPlacementsHeaders();
    }
  };

  const getTransferredPlacementsHeaders = () => {
    const transferredInTableHeaders = [
      { sortColumn: "associateNameOrAlias", label: "Associate Name" },
      { sortColumn: "previousSite", label: "Transferred From Site" },
      { sortColumn: "transferredAtDate", label: "Placement Transfer Date" },
      { sortColumn: "daysInCategory", label: "Time in Transferred Status (days)" },
      { sortColumn: "placementType", label: "Placement Type" },
      { sortColumn: "caseId", label: "Case Id" },
      { sortColumn: "status", label: "Status" }
    ];
    return <TableRow>
      {transferredInTableHeaders.map((header) => (
        <TableCell sortColumn={header.sortColumn}>{header.label}</TableCell>
      ))}
    </TableRow>
  }

  const handleAssociateFullName = (associateFullName: string | null, alias: string, id: number, index: number) =>
    associateFullName !== null ? (
      <div
        data-cy={`placements-overview__details-link-${index}`}
        onClick={() => navigate(`${PLACEMENT_DETAILS_BASEURL}/${id}`)}
      >
        <div
          className="placement-overview-table-content__associate-name"
          onClick={() => navigate(`${PLACEMENT_DETAILS_BASEURL}/${id}`)}
        >
          {associateFullName}
        </div>
        <div>{alias}@</div>
      </div>
    ) : (
      <div
        className="placement-overview-table-content__associate-name"
        onClick={() => navigate(`${PLACEMENT_DETAILS_BASEURL}/${id}`)}
      >
        {alias}@
      </div>
    );

  const handleJobs = (jobs: (string | null)[], shiftCodes: (string | null)[], jobSuffix: string) =>
    jobs.length <= 1 ? (
      <div>
        <div>{jobs[0]} {jobSuffix}</div>
        <div className="placement-overview-table-content__shift-code">{shiftCodes[0]}</div>
      </div>
    ) : (
      `${jobs.length} jobs`
    );

  const handleJobTypes = (jobTypes: (string | null)[]) =>
    jobTypes.length <= 1 ? `${jobTypes[0] ?? ""}` : `${jobTypes.length} job types`;

  const handleAllocatedJobShifts = (
    allocatedJobShifts: AllocatedJobShift[],
    jobs: (string | null)[],
    jobTypes: (string | null)[],
    shiftCodes: (string | null)[],
    jobSuffix: string
  ) =>
    allocatedJobShifts.length > 1 &&
    allocatedJobShifts.map((_: AllocatedJobShift, index: number) => (
      <TableRow key={index}>
        <TableCell />
        <TableCell>
          <div>{jobs[index]} {jobSuffix}</div>
          <div className="placement-overview-table-content__shift-code">{shiftCodes[index]}</div>
        </TableCell>
        <TableCell>{jobTypes[index]}</TableCell>
        {Array(5).fill(<TableCell />)}
      </TableRow>
    ));

  const handleStatus = (category: string, status: string) => (
    <>
      <div>{status}</div>
      <div>
        <Tag type="neutral">{category}</Tag>
      </div>
    </>
  );

  const handlePlacementEndDate = (placementEndDate: string, closenessToExpiration: ClosenessToExpiration) => (
    <>
      { closenessToExpiration === ClosenessToExpiration.LESS_THAN_7_DAYS &&
        <>
          {placementEndDate}
          <div>
            <Tag type="warning">Less than 7 days to expiration</Tag>
          </div>
        </>}
      { closenessToExpiration === ClosenessToExpiration.LESS_THAN_2_DAYS_OR_EXPIRED &&
        <>
          {placementEndDate}
          <div>
            <Tag type="error">Less than 2 days to expiration or expired</Tag>
          </div>
        </>}
      { (closenessToExpiration === ClosenessToExpiration.NOT_APPLICABLE ) &&
        <div>
          {placementEndDate}
        </div>}
    </>
  );

  const renderSortedTableData = () => {
    if(currentTab !== "transferredInPlacements") {
      return sortedTableData.map(
        (
          {
            alias,
            allocatedJobShifts,
            associateFullName,
            caseId,
            category,
            closedAtDate,
            daysInCategory,
            id,
            jobs,
            jobTypes,
            placementStartDate,
            placementEndDate,
            placementType,
            shiftCodes,
            site,
            status,
            totalAccommodatedDaysByAssociate,
            closenessToExpiration,
            jobsDisplayed,
            jobSuffix
          },
          index
        ) => (
          <UncontrolledExpandableRow key={id}>
            <TableCell>{handleAssociateFullName(associateFullName, alias, id, index)}</TableCell>
            <TableCell>{jobsDisplayed && handleJobs(jobs, shiftCodes, jobSuffix)}</TableCell>
            <TableCell>{jobsDisplayed && handleJobTypes(jobTypes)}</TableCell>
            {isUserDaliUser && <TableCell>{site}</TableCell>}
            <TableCell>{placementStartDate}</TableCell>
            <TableCell>{handlePlacementEndDate(placementEndDate, closenessToExpiration)}</TableCell>
            {currentTab === "closedPlacements" && <TableCell>{closedAtDate}</TableCell>}
            <TableCell>{daysInCategory}</TableCell>
            <TableCell>{placementType}</TableCell>
            <TableCell>{caseId}</TableCell>
            <TableCell>{handleStatus(category, status)}</TableCell>
            {currentTab !== "closedPlacements" && <TableCell>{totalAccommodatedDaysByAssociate}</TableCell>}
            {jobsDisplayed && handleAllocatedJobShifts(allocatedJobShifts, jobs, jobTypes, shiftCodes, jobSuffix)}
          </UncontrolledExpandableRow>
        )
      )
    } else {
      return getTransferredPlacementsRows(sortedTableData);
    }
  }

  const getTransferredPlacementsRows = (sortedTableData: TableDataRow[]) => {
    return sortedTableData.map(
      (
        {
          alias,
          associateFullName,
          caseId,
          category,
          placementType,
          previousSite,
          id,
          status,
          daysInCategory,
          transferredAtDate
        },
        index
      ) => (
        <UncontrolledExpandableRow key={id}>
          <TableCell>{handleAssociateFullName(associateFullName, alias, id, index)}</TableCell>
          <TableCell>{previousSite}</TableCell>
          <TableCell>{transferredAtDate}</TableCell>
          <TableCell>{daysInCategory}</TableCell>
          <TableCell>{placementType}</TableCell>
          <TableCell>{caseId}</TableCell>
          <TableCell>{handleStatus(category, status)}</TableCell>
        </UncontrolledExpandableRow>
      )
    )
  }

  return (
    <Table
      headerRows={1}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      onSort={onSort}
      showDividers={true}
      fixHeaderRows={true}
      rowComponents={[UncontrolledExpandableRow, TableRow]}
    >
      {renderTableHeaders()}
      {renderSortedTableData()}
    </Table>
  );
};

export default placementsOverviewTable;
