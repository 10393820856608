import React from "react";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import SortableList from "./sortableRegionTaskList";

const ROW_LAYOUT = ["grid-1", "grid-1", "grid-3", "grid-2", "grid-2", "grid-3"];

const FormTasksTable = () => {
  return (
    <div>
      <Row widths={ROW_LAYOUT} width="100%">
        <Text>Move</Text>
        <Text>Rank</Text>
        <Text>Task Name</Text>
        <Text>Specify Body Location?</Text>
        <Text>Measure Unit</Text>
        <Text>Description</Text>
      </Row>
      <SortableList />
    </div>
  );
};

export default FormTasksTable;
