import React, { useEffect } from "react";
import Modal from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  addOrEditShift,
  setIsCreateModalOpen,
  setSelectedShift,
  TableDataRow,
} from "src/store/updateShiftsPageSlice";
import { useForm } from "src/hooks/useForm";
import ShiftsFormFields from "../shiftsFormFields/shiftsFormFields";

const createNewShift = () => {
  const dispatch = useAppDispatch();
  const { isCreateModalOpen, selectedShift, shiftFieldLengths } = useAppSelector(
    (state) => state.updateShiftsPage
  );
  const {
    validateForm,
    handleChange,
    resetForm,
    setFormValues,
    formData,
    errors,
  } = useForm<TableDataRow>({
    validations: {
      shiftCode: {
        required: {
          value: true,
          message: "Shift code is required",
        },
        custom: {
          isValid: (value) => value.length <= shiftFieldLengths.maxShiftCodeCharLength,
          message: `Shift code must be less than or equal to ${shiftFieldLengths.maxShiftCodeCharLength} characters.`
        }
      },
      shiftLength: {
        required: {
          value: true,
          message: "Shift length is required",
        },
      },
      dayOrNight: {
        required: {
          value: true,
          message: "Day or night is required",
        },
      },
      active: {
        required: {
          value: true,
          message: "Active or inactive is required",
        },
      },
    },
    initialValues: {
      daysOfWeek: [],
      shiftCode: "",
    },
  });

  useEffect(() => {
    if (selectedShift) {
      setFormValues(selectedShift);
    }
  }, [selectedShift]);

  const onSave = () => {
    if (validateForm()) {
      dispatch(addOrEditShift(formData));
      dispatch(setIsCreateModalOpen(false));
      dispatch(setSelectedShift(undefined));
      resetForm();
    }
  };

  const onCancel = () => {
    dispatch(setIsCreateModalOpen(false));
    dispatch(setSelectedShift(undefined));
    resetForm();
  };

  return (
    <Modal
      open={isCreateModalOpen}
      onClose={onCancel}
      closeLabel="Close"
      aria-describedby="modal-description"
      width="480px"
      scrollContainer="modal"
      bodySpacingInset={"500 200 500 500"}
    >
      <Text type="h300"> {selectedShift ? "Edit" : "Add New Shift"}</Text>
      <ShiftsFormFields
        formData={formData}
        errors={errors}
        handleChange={handleChange}
      />
      <Row alignmentHorizontal="end" widths={["fill", "80px", "66px"]}>
        <div></div>
        <Button
          className="update-shifts__modal__cancel-btn"
          type="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className="update-shifts__modal__submit-btn"
          onClick={onSave}
        >
          Save
        </Button>
      </Row>
    </Modal>
  );
};

export default createNewShift;
