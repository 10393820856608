import React, { useState, useCallback, useEffect } from "react";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import Loader from "@amzn/meridian/loader";
import { TableSortDirection } from "@amzn/meridian/table/table";
import Box from "@amzn/meridian/box";
import editTokens from "@amzn/meridian-tokens/base/icon/edit";
import { useAppSelector } from "src/store/store";
import Icon from "@amzn/meridian/icon";
import { useDispatch } from "react-redux";
import UncontrolledExpandableRow from "src/components/shared/uncontrolledExpandableRow/uncontrolledExpandableRow";
import {
  setCurrentPage,
  setTotalPages,
  TableDataRow,
} from "src/store/jobConfigPageSlice";
import { getJobs } from "src/store/jobConfigPageSlice";
import { filterSortAndPaginateTableData } from "src/utils/tableUtils";
import { useNavigate } from "react-router-dom";
import ApplicableSitesPopover from "../applicableSitesPopover/applicableSitesPopover";
import { setSelectedJobId } from "src/store/createNewJobSlice";

const jobTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] =
    useState<TableSortDirection>("ascending");
  const onSort = useCallback(({ sortColumn, sortDirection } : {sortColumn: string, sortDirection: TableSortDirection}) => {
    setSortDirection(sortDirection);
    setSortColumn(sortColumn);
  }, []);
  const { tableData, searchField, currentPage, perPage } = useAppSelector(
    (state) => state.jobConfigPage
  );
  const [sortedTableData, setSortedTableData] = useState(tableData);

  useEffect(() => {
    // filter, sorting, and pagination
    const [paginatedData, totalPages] = filterSortAndPaginateTableData(
      tableData,
      searchField,
      sortColumn,
      sortDirection,
      currentPage,
      [],
      perPage
    );
    setSortedTableData(paginatedData as TableDataRow[]);
    dispatch(setTotalPages(totalPages));
  }, [sortColumn, sortDirection, tableData, searchField, currentPage, perPage]);

  useEffect(() => {
    // go back to first page when filter/sort
    dispatch(setCurrentPage(1));
  }, [searchField, sortColumn, sortDirection, perPage]);

  return (
    <Box
      overflowY="scroll"
      overflowX="scroll"
      type="outline"
      className="job-config__table"
    >
      <Table
        headerRows={1}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showDividers={true}
        fixHeaderRows={true}
        rowComponents={[UncontrolledExpandableRow, TableRow]}
      >
        <TableRow>
          <TableCell sortColumn="name">Job Name</TableCell>
          <TableCell sortColumn="region">Region</TableCell>
          <TableCell sortColumn="jobType">Type</TableCell>
          <TableCell sortColumn="siteType">Site Type</TableCell>
          <TableCell>Applicable Site(s)</TableCell>
          <TableCell sortColumn="active">Is Active?</TableCell>
          <TableCell sortColumn="description">Description</TableCell>
          <TableCell>Edit</TableCell>
        </TableRow>
        {sortedTableData.map((row) => (
          <UncontrolledExpandableRow key={row.id}>
            <TableCell>{row.name || ""}</TableCell>
            <TableCell>{row.region || ""}</TableCell>
            <TableCell>{row.jobType || ""}</TableCell>
            <TableCell>{row.siteType || ""}</TableCell>
            <TableCell>
              {row.id && <ApplicableSitesPopover jobId={row.id} />}
            </TableCell>

            <TableCell>{row.active ? "Yes" : "No"}</TableCell>
            <TableCell>{row.description || ""}</TableCell>
            <TableCell>
              <div
                className="job-config__edit-icon"
                onClick={() => {
                  dispatch(setSelectedJobId(row.id));
                  navigate("/createNewJob");
                }}
              >
                <Icon tokens={editTokens}></Icon>
              </div>
            </TableCell>
          </UncontrolledExpandableRow>
        ))}
      </Table>
    </Box>
  );
};

export default jobTable;
