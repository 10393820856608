import React, { useEffect } from "react";
import AppLayout from "@amzn/meridian/app-layout";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { getFeatureFlags, getUser } from "src/store/userSlice";
import ErrorBoundary from "src/components/shared/errorBoundary/errorBoundary";
import SiteToaster from "src/components/shared/siteToaster/siteToaster";
import Header from "src/components/shared/header/header";
import Routes from "src/components/routes/mainContent";
import AmazonConfidentialityAgreementModal from "src/components/shared/amazonConfidentialityAgreementModal/amazonConfidentialityAgreementModal";
import { setDecisionPortalFeatureFlagInNewJmrSlice } from "src/store/newJmrPageSlice";

const App = () => {
  const dispatch = useAppDispatch();
  const { token, pageLastLoadTimestamp, featureFlags } = useAppSelector((state) => state.user);
  const reduxStore = useAppSelector(state => state);

  const PAGE_TIMEOUT_IN_MILLI = 8 * 60 * 60 * 1000; // set page refresh time as 8 hours

  /**
   * Only make the getUser() call if the user has agreed to the privacy modal
   * */
  useEffect(() => {
    if (!token && localStorage.getItem("userAgreement")) {
      dispatch(getUser());
    }

    if (token) {
      dispatch(getFeatureFlags());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if(featureFlags.DP_INTEGRATION_P0) {
      dispatch(setDecisionPortalFeatureFlagInNewJmrSlice());
    }
  }, [featureFlags.DP_INTEGRATION_P0]);

  /**
   * When  interacting with page, force refresh page triggerred every 8 hour
   * to make sure UI getting the latest asset
   * */
  useEffect(() => {
    if (pageLastLoadTimestamp != 0 && Date.now() - pageLastLoadTimestamp >= PAGE_TIMEOUT_IN_MILLI) {
      window.location.reload();
    }
  }, [reduxStore]);

  return (
    <ErrorBoundary>
      <AppLayout headerComponent={Header}>
        <SiteToaster />
        <Header />
        {!localStorage.getItem("userAgreement") ? <AmazonConfidentialityAgreementModal /> : <Routes />}
      </AppLayout>
    </ErrorBoundary>
  );
};

export default App;
