import React from "react";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useDispatch } from "react-redux";
import SortableRegionTaskRow from "./sortableRegionTaskRow";
import { useAppSelector } from "src/store/store";
import { changeTasksOrder } from "src/store/regionTasksPageSlice";

const SortableRegionTaskList = () => {
  const { selectedRegionTasks } = useAppSelector((state) => state.regionTasksConfigPage);
  const dispatch = useDispatch();
  const sensors = useSensors(useSensor(PointerSensor));
  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={selectedRegionTasks.map((regionTask) => regionTask.taskId)}
        strategy={verticalListSortingStrategy}
      >
        {selectedRegionTasks.map((task) => (
          <SortableRegionTaskRow task={task} />
        ))}
      </SortableContext>
    </DndContext>
  );
  function handleDragEnd(event: any) {
    const oldIndex = event.active.data.current.sortable.index;
    const newIndex = event.over.data.current.sortable.index;
    dispatch(changeTasksOrder({ old: oldIndex, new: newIndex }));
  }
};

export default SortableRegionTaskList;
