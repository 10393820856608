import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React from "react";
import { TableDataRow } from "src/store/newJmrPageSlice";

interface leftColumnProps {
  selectedResults: TableDataRow[];
}
const leftColumn = ({ selectedResults }: leftColumnProps) => {
  return (
    <Column width="200" spacing={"none"}>
      <Row className="new-jmr__placements-table__header-row new-jmr__placements-table__left-col">
        <Text type="h100">Job Name</Text>
      </Row>

      {selectedResults?.length > 0 &&
        selectedResults.map((row, index) => (
          <Row
            height={"150px"}
            className="new-jmr__placements-table__content-row new-jmr__placements-table__left-col"
            spacing={"none"}
            spacingInset={"300"}
            overflowY="scroll"
            alignmentVertical="center"
            key={index}
          >
            <Column spacing={"200"} alignmentVertical={"center"}>
              <Text type="h100">{row.name}</Text>
              <Text type="b200" color="secondary">
                {row.shiftCode || "-"}
              </Text>
              <Text type="b200" color="secondary">
                {row.jobType}
              </Text>
            </Column>
          </Row>
        ))}

      {selectedResults?.length === 0 && (
        <Row
          className="new-jmr__placements-table__empty-row"
          widths={["fill"]}
          backgroundColor="highlight"
        ></Row>
      )}

      <Row
        className="new-jmr__placements-table__footer-row new-jmr__placements-table__left-col"
        alignmentVertical="center"
      >
        <Text type="h100">Shift Hours</Text>
      </Row>
    </Column>
  );
};

export default leftColumn;
