import React, { useEffect } from "react";
import TopOptions from "./topOptions/topOptions";
import JobTable from "./jobTable/jobTable";
import LoadingSpinner from "src/components/shared/loadingSpinner/loadingSpinner";
import Text from "@amzn/meridian/text";
import "./jobConfiguration.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { getJobs, setSearchField } from "src/store/jobConfigPageSlice";
import PaginationWithDropdown from "src/components/shared/paginationWithDropdown/paginationWithDropdown";
import { setCurrentPage, setPerPage } from "src/store/jobConfigPageSlice";

const jobConfiguration = () => {
  const dispatch = useAppDispatch();

  const { userLoading } = useAppSelector((state) => state.user);
  const { isLoading, currentPage, totalPages, perPage } = useAppSelector(
    (state) => state.jobConfigPage
  );

  useEffect(() => {
    if (userLoading === "fulfilled") {
      dispatch(getJobs());
    }

    return () => {
      dispatch(setSearchField(""));
    };
  }, [userLoading]);

  return (
    <div className="job-config">
      <Text className="job-config__content__text">
        Here you can view, edit, and add new jobs within the RTW tool
      </Text>
      {!isLoading ? (
        <>
          <TopOptions />
          <JobTable />
          <PaginationWithDropdown
            currentPage={currentPage}
            totalPages={totalPages}
            perPage={perPage}
            setPerPage={(newPerPage) => dispatch(setPerPage(newPerPage))}
            setCurrentPage={(newPage) => dispatch(setCurrentPage(newPage))}
          />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default jobConfiguration;
