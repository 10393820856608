import React, { useState } from "react";
import Text from "@amzn/meridian/text";
import Select, { SelectOption } from "@amzn/meridian/select";
import Column from "@amzn/meridian/column";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import { createSearchRegExp } from "src/utils/searchUtils";
import { setSelectedFilterOptions } from "src/store/placementOverviewPageSlice";

const multipleSiteDropdown = () => {
  const dispatch = useDispatch();

  const { availableFilterOptions, selectedFilterOptions } = useAppSelector((state) => state.placementOverviewPage);
  const allPlacementTypes = availableFilterOptions.availablePlacementTypes;

  const [searchQuery, setSearchQuery] = useState();
  const searchRegExp = createSearchRegExp(searchQuery);

  const matchedPlacementTypeOptions = allPlacementTypes.filter(
    (placementTypeName: string) => !searchQuery || searchRegExp.test(placementTypeName)
  );

  return (
    <Column spacing="100">
      <Text>Placement Type</Text>
      <Select
        size='small'
        value={selectedFilterOptions.placementTypes}
        onChange={(newValue) => {
          dispatch(setSelectedFilterOptions({ ...selectedFilterOptions, placementTypes: newValue }));
        }}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        placeholder='Enter value...'
      >
        {matchedPlacementTypeOptions.map((placementType: string) => (
          <SelectOption label={placementType} value={placementType} key={placementType} />
        ))}
        {!matchedPlacementTypeOptions.length ? (
          <Column alignmentVertical='center' spacing='300' spacingInset='500'>
            <Text alignment='center'>No results</Text>
          </Column>
        ) : null}
      </Select>
    </Column>
  );
};

export default multipleSiteDropdown;
