import React, { useRef, useState, useCallback, useEffect } from "react";
import Popover, { PopoverHeader } from "@amzn/meridian/popover";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import SearchField from "@amzn/meridian/search-field";
import { createSearchRegExp } from "src/utils/searchUtils";
import {
  getApplicableSites,
  setDisplayedApplicableSites,
} from "src/store/jobConfigPageSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { Site } from "src/store/siteSlice";

interface applicableSitesPopoverProps {
  jobId: number;
}
const applicableSitesPopover = ({ jobId }: applicableSitesPopoverProps) => {
  const dispatch = useAppDispatch();
  const buttonRef = useRef();
  const { displayedApplicableSites } = useAppSelector(
    (state) => state.jobConfigPage
  );
  const { token } = useAppSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const onClickButton = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);
  const searchRegExp = createSearchRegExp(searchText);

  useEffect(() => {
    if (open && token) {
      dispatch(getApplicableSites(jobId));
    }
    return () => {
      if (displayedApplicableSites && displayedApplicableSites?.length > 0) {
        dispatch(setDisplayedApplicableSites([]));
      }
    };
  }, [open, token]);

  return (
    <React.Fragment>
      <Button
        onClick={onClickButton}
        ref={buttonRef}
        size="medium"
        type="link"
        className="job-config__open-sites-popover-btn"
      >
        View sites
      </Button>
      <Popover
        anchorNode={buttonRef.current}
        open={open}
        onClose={onClose}
        position="bottom"
      >
        <PopoverHeader closeLabel="Close">Applicable sites</PopoverHeader>
        <SearchField
          value={searchText}
          onChange={setSearchText}
          size="small"
          onSubmit={() => {}}
          placeholder="Search for a site..."
        ></SearchField>
        <Column
          height={"200px"}
          overflowY="scroll"
          spacingInset={"400 none none none"}
        >
          {displayedApplicableSites &&
            displayedApplicableSites
              .filter((site: Site) => searchRegExp.test(site.site))
              .map((site: Site) => <Text key={site.id}>{site.site}</Text>)}
        </Column>
      </Popover>
    </React.Fragment>
  );
};

export default applicableSitesPopover;
