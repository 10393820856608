import React, { useEffect } from "react";
import Text from "@amzn/meridian/text";
import SiteTable from "./siteTable/siteTable";
import SiteModal from "./siteModal/siteModal";
import TopOptions from "./topOptions/topOptions";
import Row from "@amzn/meridian/row";
import "../taskConfiguration/taskConfiguration.scss";
import {
  getAllSites,
  getBuildingTypes,
  getCountries,
  getOrgs,
  getRegions,
  getSubOrgs,
  setSearchField,
  setCurrentPage,
  setPerPage,
} from "src/store/siteConfigPageSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import LoadingSpinner from "src/components/shared/loadingSpinner/loadingSpinner";
import PaginationWithDropdown from "src/components/shared/paginationWithDropdown/paginationWithDropdown";

const siteConfiguration = () => {
  const dispatch = useAppDispatch();

  const { isLoading, currentPage, totalPages, perPage } = useAppSelector(
    (state) => state.siteConfigPage
  );
  const { userLoading } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (userLoading === "fulfilled") {
      dispatch(getAllSites());
      dispatch(getCountries());
      dispatch(getRegions());
      dispatch(getOrgs());
      dispatch(getSubOrgs());
      dispatch(getBuildingTypes());
    }
    return () => {
      dispatch(setSearchField(""));
    };
  }, [userLoading]);

  return (
    <div className="view-and-edit-tasks">
      <Row className="view-and-edit-tasks__content__text">
        <Text alignment="center">
          Site configuration allows you to view, edit, and update sites viewable
          within the RTW tool.
        </Text>
      </Row>

      {!isLoading ? (
        <>
          <TopOptions />
          <SiteTable />
          <SiteModal />
          <PaginationWithDropdown
            currentPage={currentPage}
            totalPages={totalPages}
            perPage={perPage}
            setPerPage={(newPerPage) => dispatch(setPerPage(newPerPage))}
            setCurrentPage={(newPage) => dispatch(setCurrentPage(newPage))}
          />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default siteConfiguration;
