import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, { useEffect, useState } from "react";
import {
  JobTask,
  setIsDisabled,
  setJobFormData,
  setSelectedTaskName,
} from "src/store/createNewJobSlice";
import { useAppSelector } from "src/store/store";
import Column from "@amzn/meridian/column";
import { useDispatch } from "react-redux";
import Input from "@amzn/meridian/input";
import {
  generatePlaceholderForRequirementName,
  validateTask,
} from "../../../../utils/requirementFormUtils";
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group";
import Button from "@amzn/meridian/button";

const requirementForm = () => {
  const dispatch = useDispatch();
  const { selectedTaskName, jobFormData, requirementFormErrors } =
    useAppSelector((state) => state.createNewJobPage);
  const [selectedTask, setSelectedTask] = useState<Partial<JobTask>>();

  useEffect(() => {
    if (selectedTask?.label !== selectedTaskName) {
      const existing = jobFormData?.tasks?.filter(
        (task) => task?.label === selectedTaskName
      )[0];
      setSelectedTask({
        ...existing,
        bodyLocationType: existing?.bodyLocationType
          ? existing?.bodyLocationType
          : "R",
      });
    }
  }, [selectedTaskName]);

  const onCancel = () => {
    dispatch(setSelectedTaskName(undefined));
    dispatch(setIsDisabled(false));
  };

  const onSave = () => {
    if (selectedTask && validateTask(selectedTask)) {
      const newTasks = jobFormData?.tasks.map((task) =>
        task?.label === selectedTaskName ? selectedTask : task
      );
      dispatch(setJobFormData({ ...jobFormData, tasks: newTasks }));
      dispatch(setSelectedTaskName(undefined));
      dispatch(setIsDisabled(false));
    }
  };

  return (
    <React.Fragment>
      {selectedTaskName && (
        <>
          <Row widths={"fill"} spacingInset={"400 none"}>
            <Column spacing={"200"}>
              <Text tag="label" type="b300" color="primary">
                Task Requirement
              </Text>
              <Text tag="label" type="b300" color="secondary">
                {selectedTask?.measureUnit || ""}
              </Text>
              {selectedTask?.measureUnit !== "YES/NO" ? (
                <Input
                  placeholder={generatePlaceholderForRequirementName(
                    selectedTask?.measureUnit
                  )}
                  value={selectedTask?.requirementName}
                  onChange={(requirementName) =>
                    setSelectedTask({ ...selectedTask, requirementName })
                  }
                  error={
                    requirementFormErrors[selectedTaskName]?.requirementName
                      ?.error
                  }
                  errorMessage={
                    requirementFormErrors[selectedTaskName]?.requirementName
                      ?.errorMessage
                  }
                />
              ) : (
                <>
                  <ButtonGroup
                    value={selectedTask?.requirementName}
                    onChange={(requirementName) => {
                      setSelectedTask({
                        ...selectedTask,
                        requirementName,
                      });
                    }}
                  >
                    <ButtonOption value="Yes">Yes</ButtonOption>
                    <ButtonOption value="No">No</ButtonOption>
                  </ButtonGroup>
                </>
              )}
            </Column>
          </Row>
          {selectedTask?.identifyBodyLocation && (
            <Row widths={"fill"}>
              <Column spacing={"200"}>
                <Text tag="label" type="b300" color="primary">
                  Identify Body Location
                </Text>
                <ButtonGroup
                  value={selectedTask?.bodyLocationType}
                  onChange={(bodyLocationType) => {
                    setSelectedTask({
                      ...selectedTask,
                      bodyLocationType,
                    });
                  }}
                >
                  <ButtonOption value="L">Left</ButtonOption>
                  <ButtonOption value="R">Right</ButtonOption>
                  <ButtonOption value="B">Both</ButtonOption>
                </ButtonGroup>
              </Column>
            </Row>
          )}
          <Row alignmentHorizontal="end" widths={["fill", "80px", "66px"]}>
            <div></div>
            <Button type="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={onSave}
              className="create-new-job__save-task"
            >
              Save
            </Button>
          </Row>
        </>
      )}
    </React.Fragment>
  );
};

export default requirementForm;
