import React, { useState } from "react";
import Text from "@amzn/meridian/text";
import Select, { SelectOption } from "@amzn/meridian/select";
import Column from "@amzn/meridian/column";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import { createSearchRegExp } from "src/utils/searchUtils";
import { setSelectedFilterOptions } from "src/store/placementOverviewPageSlice";

const multipleJobDropdown = () => {
  const dispatch = useDispatch();
  const { availableFilterOptions, selectedFilterOptions } = useAppSelector((state) => state.placementOverviewPage);

  const availableJobs = availableFilterOptions.availableJobs;

  const [searchQuery, setSearchQuery] = useState();
  const searchRegExp = createSearchRegExp(searchQuery);

  const matchedJobOptions = availableJobs.filter((jobName: string) => !searchQuery || searchRegExp.test(jobName));

  return (
    <Column spacing="100">
      <Text>Job</Text>
      <Select
        size='small'
        value={selectedFilterOptions.jobs}
        onChange={(newValue) => {
          dispatch(setSelectedFilterOptions({ ...selectedFilterOptions, jobs: newValue }));
        }}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        placeholder='Enter value...'
      >
        {matchedJobOptions.map((jobName: string) => (
          <SelectOption label={jobName} value={jobName} key={jobName} />
        ))}
        {!matchedJobOptions.length ? (
          <Column alignmentVertical='center' spacing='300' spacingInset='500'>
            <Text alignment='center'>No results</Text>
          </Column>
        ) : null}
      </Select>
    </Column>
  );
};

export default multipleJobDropdown;
