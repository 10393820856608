import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import BreadCrumbs from "src/components/shared/breadcrumbs/breadcrumbs";
import ConfirmModal from "src/components/shared/confirmModal/confirmModal";
import PageControls from "./pageControls/pageControls";
import SingleSiteSelection from "./singleSiteSelection/singleSiteSelection";
import MultipleSiteSelection from "./multipleSiteSelection/multipleSiteSelection";
import {
  getAllowedSites,
  getUserSites,
  updateUserSites,
} from "src/store/siteSlice";
import {
  setAreMultipleSitesSelected,
  setDefaultHomesiteDropdownValue,
  setMultipleSitesDropdownValues,
  setPageDisabled,
} from "src/store/defaultSettingsPageSlice";
import "./defaultSettings.scss";
import MultipleSiteToggle from "./multipleSiteToggle/multipleSiteToggle";
import Box from "@amzn/meridian/box";
import { Site } from "src/store/siteSlice";

const generalSettings = () => {
  const dispatch = useAppDispatch();
  const { userLoading, alias } = useAppSelector((state) => state.user);
  const { userSites, allowedSites } = useAppSelector((state) => state.sites);
  const {
    areMultipleSitesSelected,
    defaultHomesiteDropdownValue,
    multipleSitesDropdownValues,
  } = useAppSelector((state) => state.defaultSettingsPage);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);

  useEffect(() => {
    // get initial data
    if (userLoading === "fulfilled") {
      dispatch(getAllowedSites());
    }
    if (userLoading === "fulfilled" && alias !== "") {
      dispatch(getUserSites({ alias }));
    }
  }, [alias]);

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current && userSites.length > 0) {
      if (userSites.length === 1) {
        dispatch(setAreMultipleSitesSelected(false));
        dispatch(setDefaultHomesiteDropdownValue(userSites[0].id));
        dispatch(setMultipleSitesDropdownValues([]));
      } else if (userSites.length > 1) {
        dispatch(setAreMultipleSitesSelected(true));
        dispatch(setDefaultHomesiteDropdownValue(""));
        dispatch(
          setMultipleSitesDropdownValues(userSites.map((site) => site.id))
        );
      }
      initialRender.current = false;
    }
  }, [userSites]);

  useEffect(() => {
    // when toggle is set to true, clear out defaultHomeSite
    if (areMultipleSitesSelected) {
      dispatch(setDefaultHomesiteDropdownValue(""));
    }
  }, [areMultipleSitesSelected]);

  /**
   * On cancel function
   */
  const onCancel = () => {
    dispatch(setPageDisabled(true));
    dispatch(getUserSites({ alias }));
  };

  /**
   * Reset form fields
   */
  const onReset = () => {
    dispatch(setAreMultipleSitesSelected(false));
    dispatch(setDefaultHomesiteDropdownValue(undefined));
    dispatch(setMultipleSitesDropdownValues([]));
  };

  /**
   * On submit function
   */
  const onSubmit = () => {
    let sites: Site[] = [];
    sites = areMultipleSitesSelected
      ? allowedSites?.filter(
          (site) => multipleSitesDropdownValues.indexOf(site.id) !== -1
        )
      : allowedSites?.filter(
          (site) => defaultHomesiteDropdownValue === site.id
        );
    dispatch(updateUserSites({ alias, sites }));
    dispatch(getUserSites({ alias }));
  };

  return (
    <div className="default-settings">
      <BreadCrumbs
        breadcrumbList={[
          { label: "Return To Work 2.0", url: "/" },
          { label: alias, url: "#" },
          { label: "Default Settings", url: "/defaultSettings" },
        ]}
      />

      <Box
        type="outline"
        spacingInset="500 400"
        className="default-settings__content"
      >
        <SingleSiteSelection />
        <MultipleSiteToggle />
        <MultipleSiteSelection />
        <PageControls
          onResetButtonClick={() => setIsResetModalOpen(true)}
          onSubmit={() => onSubmit()}
          onCancel={() => setIsCancelModalOpen(true)}
        />
      </Box>

      <ConfirmModal
        modalTitle="Confirm cancellation?"
        modalDescription="This will reset your settings to the previously submitted state."
        open={isCancelModalOpen}
        setOpen={setIsCancelModalOpen}
        onConfirm={() => onCancel()}
      />

      <ConfirmModal
        modalTitle="Confirm reset?"
        modalDescription="This will reset your settings to the original state."
        open={isResetModalOpen}
        setOpen={setIsResetModalOpen}
        onConfirm={() => onReset()}
      />
    </div>
  );
};

export default generalSettings;
