import React from "react";
import Box from "@amzn/meridian/box";
import RadioButton from "@amzn/meridian/radio-button";
import Link from "@amzn/meridian/link";
import exportSmallTokens from "@amzn/meridian-tokens/base/icon/export-small";
import Theme from "@amzn/meridian/theme";
// import blueLightTokens from "@amzn/meridian-tokens/theme/blue-light";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import { CaseSelectionProps } from "./caseSelectionProps";
import { constructIncidentLink } from "src/utils/constructRTWLinks";
import { stripOutLeadingHash } from "src/utils/helpers";

const SelectableCase = ({ austinCase, handleChange, selectedCaseId, currentAccommodationId }: CaseSelectionProps) => {
  const selectableByEveryone = austinCase.selectable;
  const { injuryDate, principalBodyPart, caseId, accommodationId, updatedAt } = austinCase;

  return (
    <Theme palette="blue" mode="light">
      <Box type="outline" spacingInset="400">
        <Row widths={["grid-1", "grid-5", "fit", "grid-6"]} alignmentVertical="top">
          <Column>
            <Row>
              <RadioButton checked={caseId === selectedCaseId} onChange={handleChange} value={caseId}></RadioButton>
            </Row>
          </Column>
          <Column spacing="200">
            <Row>
              <Text type="h100">Case ID: {caseId}</Text>
            </Row>
            <Row>
              <Text>{principalBodyPart} Injury</Text>
            </Row>
            {!selectableByEveryone && currentAccommodationId !== accommodationId && (
              <Column spacing="300">
                <Row></Row>
                <Row>
                  {
                    <>
                      <Text>
                        This AUSTIN case ID is already linked to an{" "}
                        <Link
                          type="secondary"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/placementDetails/${accommodationId}`}
                        >
                          RTW placement <Icon tokens={exportSmallTokens} />
                        </Link>
                        .
                      </Text>
                    </>
                  }
                </Row>
              </Column>
            )}
          </Column>
          <Column spacing="200">
            <Row>
              <Text color="secondary" type="b300">
                Last updated: {new Date(updatedAt).toLocaleDateString()}
              </Text>
            </Row>
            {!!injuryDate && (
              <Row>
                <Text color="secondary" type="b300">
                  Date of incident: {new Date(injuryDate).toLocaleDateString()}
                </Text>
              </Row>
            )}
            <Row>
              <Link
                type="secondary"
                target="_blank"
                rel="noopener noreferrer"
                href={constructIncidentLink(stripOutLeadingHash(caseId))}
              >
                View Case Details
              </Link>
            </Row>
          </Column>
        </Row>
      </Box>
    </Theme>
  );
};
export default SelectableCase;
