import React from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import { TableDataRow } from "src/store/newJmrPageSlice";
import { useAppSelector } from "src/store/store";
import { SummationDataForTask } from "../placementsSelectionTable";
import "./middleColumn.scss";

interface middleColumnProps {
  selectedResults: TableDataRow[];
  sumDataForTask: SummationDataForTask[];
}
const middleColumn = ({
  selectedResults,
  sumDataForTask,
}: middleColumnProps) => {
  const { selectedTasks } = useAppSelector((state) => state.newJmrPage);

  return (
    <Column className="scroll-overlay" width={"fill"} spacing={"none"}>
      <Row
        widths={"fill"}
        spacing={"none"}
        alignmentHorizontal="start"
        height={"52px"}
      >
        {selectedResults?.length > 0 && selectedTasks?.length > 0 ? (
          selectedTasks.map((task, index) => (
            <Column
              spacing={"none"}
              minWidth="200px"
              alignmentVertical={"center"}
              className="new-jmr__placements-table__header-row"
              key={index}
            >
              <Text type="h100">{task.label}</Text>
            </Column>
          ))
        ) : (
          <Column className="new-jmr__placements-table__header-row"></Column>
        )}
      </Row>
      {selectedResults?.length > 0 &&
        selectedResults.map((row, index) => (
          <Row
            widths={"fill"}
            spacing={"none"}
            alignmentHorizontal="start"
            key={index}
          >
            {selectedResults.length > 0 && selectedTasks?.length > 0 ? (
              selectedTasks.map((task, index) => (
                <Column
                  spacing={"none"}
                  minWidth="200px"
                  alignmentVertical={"center"}
                  className="new-jmr__placements-table__content-row"
                  key={index}
                >
                  <Text type="b200">
                    {`${
                      row.allocatedTasks.filter(
                        (t) => t.taskId === task.taskId
                      )[0]?.assignedValue || 0
                    } hours`}
                  </Text>
                </Column>
              ))
            ) : (
              <Column className="new-jmr__placements-table__content-row"></Column>
            )}
          </Row>
        ))}
      {selectedResults?.length === 0 && (
        <Row
          className="new-jmr__placements-table__empty-row"
          widths={["fill"]}
          alignmentHorizontal="center"
          alignmentVertical={"center"}
          backgroundColor="highlight"
        >
          <Text type="b200" color="secondary" alignment="center">
            No Job Placements have been assigned to the Associate.
          </Text>
        </Row>
      )}
      <Row widths={"fill"} spacing={"none"}>
        {sumDataForTask.length > 0 ? (
          sumDataForTask.map((summationData, index) => (
            <Column
              spacing={"none"}
              minWidth="200px"
              alignmentVertical={"center"}
              className="new-jmr__placements-table__footer-row"
              key={index}
            >
              {selectedResults.length > 0 && (
                <>
                  <Text
                    type="b200"
                    key={index}
                    color={
                      summationData.unusedHours >= 0 ||
                      !Number.isInteger(+summationData.restrictedValue)
                        ? "primary"
                        : "error"
                    }
                  >
                    {`${summationData.totalHours} hours`}
                  </Text>
                  <Text
                    type="b100"
                    color={
                      summationData.unusedHours >= 0 ||
                      !Number.isInteger(+summationData.restrictedValue)
                        ? "secondary"
                        : "error"
                    }
                  >
                    {summationData.unusedHours >= 0
                      ? `Hours unused: ${summationData.unusedHours}`
                      : `Exceeds ${summationData.restrictedValue || 0} hours`}
                  </Text>
                </>
              )}
            </Column>
          ))
        ) : (
          <Column className="new-jmr__placements-table__footer-row"></Column>
        )}
      </Row>
    </Column>
  );
};
export default middleColumn;
