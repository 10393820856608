import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { createToast } from "src/store/toastsSlice";
import { useParams } from "react-router-dom";
import { TOAST_TIMEOUT } from "src/config/Toast";
import CaseSource from "src/components/pages/newJMR/backgroundPage/associateInformation/caseSource/caseSource";
import { AUSTIN } from "src/components/pages/newJMR/backgroundPage/associateInformation/caseSource/constant";
import RestrictionInformation from "src/components/pages/newJMR/backgroundPage/restrictionInformation/restrictionInformation";
import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Expander from "@amzn/meridian/expander";
import Icon from "@amzn/meridian/icon";
import Image from "@amzn/meridian/image";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import searchTokens from "@amzn/meridian-tokens/base/icon/search";
import {
  clearAustinCasesByAlias,
  BackgroundFormData,
  getAustinCasesByAlias,
  getEmployeeInfo,
  JmrFlowTypes,
  setBackgroundFormData,
  setSearchedAlias,
} from "src/store/newJmrPageSlice";
import Alert from "@amzn/meridian/alert";
import { createSearchRegExp } from "src/utils/searchUtils";
import { PAPI_FIELDS } from "src/config/PapiFields";
import { BADGE_PHOTO_THUMBNAIL_URL } from "src/config/ExternalLinks";
import { getExampleCaseIdText } from "src/utils/helpers";
import CaseSelection from "src/components/shared/caseSelection/caseSelection";
import { AustinCasesByAlias } from "src/services/incident/types/austinCasesByAlias";
import { sortBasedOnStrategy, SortStrategy } from "src/utils/sortStrategies";
import { stripOutLeadingHash } from "src/utils/helpers";
import Loader from "@amzn/meridian/loader";

interface associateInformationProps {
  handleChange: (key: keyof BackgroundFormData, value: any) => void;
}
const AssociateInformation = ({ handleChange }: associateInformationProps) => {
  const dispatch = useAppDispatch();
  const { caseId, login, type } = useParams();
  const {
    austinCasesByAlias,
    backgroundFormErrors,
    isAUSTINDown,
    jmrFlowType,
    searchedAlias,
    isLoadingPAPI,
    isLoadingIncident,
    showCaseIdRequiredText } = useAppSelector(
      (state) => state.newJmrPage
    );
  const backgroundFormData = useAppSelector((state) => state.newJmrPage.backgroundFormData);
  const { alias, caseSource, employeeID, fullName, job, managerAlias, managerName, site, siteLocation } =
    backgroundFormData;
  const { allowedSites } = useAppSelector((state) => state.sites);
  const {placementId} = useParams();

  const [selectableAustinCases, setSelectableAustinCases] = useState<AustinCasesByAlias[]>([]);
  const [unselectableAustinCases, setUnselectableAustinCases] = useState<AustinCasesByAlias[]>([]);
  const [isSelectableCaseExpanderOpen, setIsSelectableCaseExpanderOpen] = useState(true);
  const [isUnselectableCaseExpanderOpen, setIsUnselectableCaseExpanderOpen] = useState(false);

  // Update avatar image when backgroundFormData changes
  useEffect(() => {
    dispatch(setSearchedAlias(alias));
  }, [employeeID]);

  // prefill data when access /newJMR/:type/:login/:caseId
  useEffect(() => {

    if (login && caseId && type) {
      dispatch(getEmployeeInfo(login));
      dispatch(clearAustinCasesByAlias());
      dispatch(getAustinCasesByAlias(login));
      dispatch(
          setBackgroundFormData({
            ...backgroundFormData,
            alias: login,
            caseID: caseId,
            caseSource: AUSTIN,
          })
      );
    }
  }, []);

  const [siteSearchQuery, setSiteSearchQuery] = useState();
  const siteSearchRegExp = createSearchRegExp(siteSearchQuery);
  const matchedSiteOptions = allowedSites
    ?.map((site) => ({ label: site.site, value: site.site }))
    .filter((option) => !siteSearchQuery || siteSearchRegExp.test(option.label));

  useEffect(() => {
    const siteInAllowedSites = allowedSites?.filter((s) => s.site === siteLocation)[0];

    if (siteLocation && siteLocation !== site?.site) {
      dispatch(
        setBackgroundFormData({
          ...backgroundFormData,
          site: siteInAllowedSites,
          siteLocation: siteInAllowedSites ? siteLocation : undefined,
        })
      );
    }
  }, [siteLocation]);

  const createInputField = (
    fieldName: keyof BackgroundFormData,
    label: string,
    placeholder: string,
    disabled: boolean = true,
    className?: string,
    children?: any,
    handleChangeWrapper = handleChange
  ) => (
    <Column spacing="200" className={className}>
      <Text tag="label" type="b300" color="primary">
        {label}
      </Text>
      <Input
        data-cy={`new-jmr__background__${fieldName}`}
        type="text"
        placeholder={placeholder}
        value={backgroundFormData[fieldName] as string}
        onChange={(value) => handleChangeWrapper(fieldName, value)}
        error={backgroundFormErrors[fieldName]?.error}
        errorMessage={backgroundFormErrors[fieldName]?.errorMessage}
        disabled={disabled && (jmrFlowType !== JmrFlowTypes.CreateNewAccommodation || PAPI_FIELDS.includes(fieldName))}
      >
        {children}
      </Input>
    </Column>
  );

  const renderCaseDetails = (austinCases: AustinCasesByAlias[], unselectableOverride = false) => {
    const caseSelections = austinCases.map((austinCase) => {
      return (
        <CaseSelection
          unselectableOverride={unselectableOverride}
          austinCase={austinCase}
          handleChange={() => handleChange("caseID", austinCase.caseId)}
          selectedCaseId={stripOutLeadingHash(backgroundFormData.caseID)}
          currentAccommodationId={placementId ? +placementId : undefined}
        ></CaseSelection>
      );
    });
    return <Column spacing="400">{caseSelections}</Column>;
  };

  useEffect(() => {
    const filteredCases = austinCasesByAlias?.filter(({ siteName }) => siteName === site?.site);
    setUnselectableAustinCases(
      sortBasedOnStrategy(
        SortStrategy.DATE,
        filteredCases?.filter((austinCase) => !austinCase.selectable),
        "updatedAt",
        "descending"
      )
    );
    setSelectableAustinCases(
      sortBasedOnStrategy(
        SortStrategy.DATE,
        filteredCases?.filter((austinCase) => austinCase.selectable),
        "updatedAt",
        "descending"
      )
    );
  }, [austinCasesByAlias, site]);

  const handleRenderAustinCaseRows = () => {
    if(JmrFlowTypes.CreateNewAccommodation !== jmrFlowType) {
      if(backgroundFormData.caseIdValid) {
        // Over here austinCases should only have one case.
        const austinCases = austinCasesByAlias
          .filter((austinCase) => austinCase.caseId === stripOutLeadingHash(backgroundFormData.caseID));
        return (
          <div>
            {renderCaseDetails(austinCases, false)}
          </div>
        )
      }
    }

    if (isAUSTINDown) {
      return (
        <Row spacingInset={"400 none"}>
          <Alert data-cy="new-jmr__austin-alert" type="warning">
            <Text type="h100">AUSTIN Server Unavailable</Text>
            <Text>
              AUSTIN server unavailable. Please proceed with current JMR creation and update this placement with the
              correct AUSTIN case when connection is restored.
            </Text>
          </Alert>
        </Row>
      );
    }

    if (!site && austinCasesByAlias?.length > 0) {
      return <Text>Please select a Site Location</Text>;
    }

    const renderCaseIdRequiredText = () => {
      return (
        <Row spacingInset={"400 none"}>
          <Alert data-cy="new-jmr__austin-alert" type="error">
            <Text type="h100">AUSTIN case ID is required</Text>
            <Text>
              Please select an available AUSTIN case ID associated with this alias and site location.
              If no AUSTIN case ID is available, please select "Yes" for "Does this case require a Return to Work (RTW) placement? on the "Medical Attachments" page of the AUSTIN case.
              Reminder: you must save and submit within AUSTIN after uploading an RFI.
            </Text>
          </Alert>
        </Row>
      );
    }

    if (selectableAustinCases?.length === 0 && unselectableAustinCases?.length === 0) {
      return renderCaseIdRequiredText();
    }

    return (
      <>
        { showCaseIdRequiredText && renderCaseIdRequiredText() }

        {selectableAustinCases?.length > 0 && (
          <Column>
            <Row></Row>
            <Expander
              title="Selectable cases (choose one case to continue):"
              open={isSelectableCaseExpanderOpen}
              onChange={setIsSelectableCaseExpanderOpen}
              type="list"
            >
              {renderCaseDetails(selectableAustinCases)}
            </Expander>
          </Column>
        )}
        {unselectableAustinCases?.length > 0 && (
          <Column>
            <Row></Row>
            <Expander
              title="Unselectable cases:"
              open={isUnselectableCaseExpanderOpen}
              onChange={setIsUnselectableCaseExpanderOpen}
              type="list"
            >
              {renderCaseDetails(unselectableAustinCases, true)}
            </Expander>
          </Column>
        )}
      </>
    );
  };

  const showCaseSourceErrorToast = () => {
    dispatch(
      createToast({
        type: "error",
        message: "Case Source is required.",
        timeout: TOAST_TIMEOUT,
      })
    );
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (searchedAlias) {
      timeoutId = setTimeout(() => {
        if (caseSource === "AUSTIN" && austinCasesByAlias?.length === 0 && searchedAlias?.length > 0 && !isAUSTINDown) {
          dispatch(
            createToast({
              type: "error",
              message: "No AUSTIN cases found for this alias.",
              timeout: TOAST_TIMEOUT,
            })
          );
        }
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [austinCasesByAlias, searchedAlias, isAUSTINDown]);

  return (
    (<Box>
      <Column spacing={"200"}>
        <Text type="h300">Associate Information</Text>
        <Text type="b300" color="secondary">
          Search for the Associate using the alias field and magnifying glass. Complete any missing information.
        </Text>
      </Column>
      <Row widths={["15%", "30%", "45%"]} spacingInset={"100 none 450 none"} alignmentVertical={"top"}>
        <CaseSource
          handleChange={handleChange}
          isNewJMR={jmrFlowType === JmrFlowTypes.CreateNewAccommodation}
        ></CaseSource>
        {createInputField(
          "alias",
          "Alias",
          "Associate's alias",
          true,
          "new-jmr__background-page__alias",
          <div
            data-cy="new-jmr__background__alias__search"
            className="new-jmr__background-page__alias__search-icon"
            onClick={ async () => {
              if (!backgroundFormData.caseSource) {
                showCaseSourceErrorToast();
                return;
              }
              await dispatch(getEmployeeInfo(alias));
              if (caseSource === "AUSTIN") {
                dispatch(clearAustinCasesByAlias());
                dispatch(getAustinCasesByAlias(alias));
              }
            }}
          >
            <Icon tokens={searchTokens} />
          </div>,
          (key, value) => handleChange(key, value.replace(/[^A-Za-z0-9]/g, ""))
        )}
        {!isLoadingPAPI && !isLoadingIncident && fullName && alias === searchedAlias && (
          <Row widths={["fill", "25%"]} spacingInset={"none 450"} alignmentVertical={"top"}>
            <Column spacing="200">
              <Text tag="label" type="b300" color="primary">
                Site Location
              </Text>
              <Select
                data-cy="new-jmr__background__siteLocation"
                searchQuery={siteSearchQuery}
                onSearch={setSiteSearchQuery}
                placeholder="Associate’s home site"
                value={siteLocation}
                onChange={(siteLocation) => {
                  handleChange("siteLocation", siteLocation);
                }}
                error={backgroundFormErrors.siteLocation?.error}
                errorMessage={backgroundFormErrors.siteLocation?.errorMessage}
                disabled={jmrFlowType !== JmrFlowTypes.CreateNewAccommodation}
              >
                {matchedSiteOptions.map((option) => (
                  <SelectOption
                    data-cy="new-jmr__background__siteLocation-item"
                    label={option.label}
                    value={option.value}
                    key={option.value}
                  />
                ))}
                {!matchedSiteOptions.length ? (
                  <Column alignmentVertical="center" spacing="300" spacingInset="500">
                    <Text alignment="center">No sites found</Text>
                  </Column>
                ) : null}
              </Select>
            </Column>
          </Row>
        )}
      </Row>
      {(isLoadingPAPI || isLoadingIncident) ? (
              <Box className="center-loader">
                  <Loader/>
              </Box>) :
          (fullName && alias === searchedAlias && (
        <>
          <Column>
            <Row width="100%" widths={["45%", "45%", "25%"]} alignmentHorizontal="start" alignmentVertical="top">
              <Box type="outline" minHeight={140}>
                <Row widths={["fit", "fill"]} alignmentHorizontal="start" alignmentVertical="center">
                  <Column heights="fit" alignmentHorizontal="start" alignmentVertical="center">
                    <Box minWidth={120}>
                      <Image
                        data-cy="new-jmr__background__user-avatar"
                        viewportHeight="160px"
                        src={`${BADGE_PHOTO_THUMBNAIL_URL}${searchedAlias}`}
                      />
                    </Box>
                  </Column>
                  <Column>
                    <Text data-cy="new-jmr__background__username" type="h100">
                      {fullName}
                      <Text data-cy="new-jmr__background__job" type="b200">
                        {job}
                      </Text>
                    </Text>
                    <Text data-cy="new-jmr__background__employee-id" type="b200">
                      {`Employee ID: ${employeeID}`}
                      <Text
                        data-cy="new-jmr__background__manager-name-and-alias"
                        type="b200"
                      >{`Manager: ${managerName} @${managerAlias}`}</Text>
                    </Text>
                  </Column>
                </Row>
              </Box>
              <Column spacingInset={"200 450"}>
                {createInputField("shift", "Shift", "Shift Code (i.e. DCA-0730)", false)}
                {createInputField("shiftLength", "Normal Shift Length (hours)", "Shift length by hours", false)}
              </Column>
            </Row>
          </Column>
          <Divider spacingBefore={"450"} />
          <Row spacingInset={"450 none"} alignmentVertical={"top"}>
            <RestrictionInformation handleChange={handleChange} />
          </Row>
          <Divider spacingBefore={"450"} spacingAfter={"450"} />
          {caseSource === "DALI" || caseSource === "BALI" ? (
            <>
              <Text type="h300">Case ID</Text>
              <Row widths={"fill"} spacingInset={"300 none"} alignmentVertical={"top"}>
                {createInputField("caseID", "", `${getExampleCaseIdText(caseSource)}`)}
              </Row>
            </>
          ) : (
            <>
              <Column spacing="400">
                <Text type="h300">{`AUSTIN cases associated with ${fullName.split(" ")[0]} `}</Text>
                {handleRenderAustinCaseRows()}
              </Column>
            </>
          )}
        </>
      ))}
    </Box>)
  );
};

export default AssociateInformation;
