import React, { useEffect } from "react";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import closeCircleTokens from "@amzn/meridian-tokens/base/icon/close-circle";
import Icon from "@amzn/meridian/icon";
import {
  JmrTask,
  setJmrResults,
  TableDataRow,
} from "src/store/newJmrPageSlice";
import { useAppSelector } from "src/store/store";
import {
  calculatePlacementHours,
  isTotalUnusedHoursValid,
} from "../placementSelectionUtils";
import { useDispatch } from "react-redux";
import "./rightColumn.scss";

interface rightColumnProps {
  selectedResults: TableDataRow[];
  unusedTotalHours: number;
}
const rightColumn = ({
  selectedResults,
  unusedTotalHours,
}: rightColumnProps) => {
  const dispatch = useDispatch();
  const { backgroundFormData, jmrResults, selectedTasks } = useAppSelector(
    (state) => state.newJmrPage
  );

  const uncheckJob = (id: string) => {
    dispatch(
      setJmrResults(
        jmrResults.map((r) => (r.id === id ? { ...r, isChecked: false } : r))
      )
    );
  };

  const setTotalHours = (id: string, totalHours: string) => {
    dispatch(
      setJmrResults(
        jmrResults.map((jmrResult) => {
          if (jmrResult.id === id) {
            const newJmrResult = { ...jmrResult, totalHours };
            return {
              ...newJmrResult,
              allocatedTasks: selectedTasks.map((task) => ({
                taskId: task.taskId,
                taskName: task.label,
                assignedValue: calculatePlacementHours(newJmrResult, task),
              })),
            };
          } else {
            return jmrResult;
          }
        })
      )
    );
  };

  const isNotMoreThanZero = (val: number) => {
    return isNaN(val) || val <= 0;
  }

  return (
    <Column width={"145px"} spacing={"none"}>
      <Row className="new-jmr__placements-table__header-row new-jmr__placements-table__right-col">
        <Text type="h100">Total Hours</Text>
      </Row>

      {selectedResults?.length > 0 &&
        selectedResults.map((row, index) => (
          <Row
            className="new-jmr__placements-table__content-row new-jmr__placements-table__right-col"
            widths={["54px", "fill", "44px"]}
            height={"150px"}
            spacing={"none"}
            key={index}
          >
            <Input
              data-cy={`new-jmr__results__totalHours-${index}`}
              type="text"
              value={row.totalHours}
              onChange={(totalHours: string) =>
                setTotalHours(row.id, totalHours)
              }
              className={isNotMoreThanZero(+row.totalHours) ? "new-jmr__results__totalHours--error" : ""}
              error={isNotMoreThanZero(+row.totalHours)}
              errorMessage={isNotMoreThanZero(+row.totalHours) ? "Must be a number greater than zero." : ""}
            />
            <div />
            <Button
              type="link"
              onClick={() => uncheckJob(row.id)}
              className={isNotMoreThanZero(+row.totalHours) ? "new-jmr__uncheck-job--error" :"new-jmr__uncheck-job"}
            >
              <Icon tokens={closeCircleTokens} />
            </Button>
          </Row>
        ))}
      {selectedResults?.length === 0 && (
        <Row
          className="new-jmr__placements-table__empty-row"
          widths={["fill"]}
          backgroundColor="highlight"
        ></Row>
      )}
      <Column
        spacing={"none"}
        alignmentVertical={"center"}
        className="new-jmr__placements-table__footer-row new-jmr__placements-table__right-col"
      >
        {selectedResults.length > 0 && (
          <>
            <Text
              type="h100"
              color={
                isTotalUnusedHoursValid(
                  unusedTotalHours,
                  backgroundFormData.shiftLength,
                  +backgroundFormData.limitedHoursPerDay
                )
                  ? "primary"
                  : "error"
              }
            >
              {`${selectedResults
                .map((r) => +r.totalHours)
                .reduce((prev, curr) => prev + curr)
                .toFixed(1)} hours`}
            </Text>
            <Text
              type="b100"
              color={
                isTotalUnusedHoursValid(
                  unusedTotalHours,
                  backgroundFormData.shiftLength,
                  +backgroundFormData.limitedHoursPerDay
                )
                  ? "secondary"
                  : "error"
              }
            >
              {unusedTotalHours >= 0
                ? `Hours unused: ${unusedTotalHours}`
                : `Exceeds ${
                    backgroundFormData.limitedHoursPerDay
                      ? backgroundFormData.limitedHoursPerDay
                      : backgroundFormData.shiftLength
                  } hours`}
            </Text>
          </>
        )}
      </Column>
    </Column>
  );
};

export default rightColumn;
