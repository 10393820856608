import Button from "@amzn/meridian/button";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import "./editAssociateInfoModal.scss";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Input from "@amzn/meridian/input";
import React, { useEffect, useState } from "react";
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group";
import LoadingSpinner from "src/components/shared/loadingSpinner/loadingSpinner";
import {
  setIsEditAssociateInfoModalOpen,
  getEmployeeInfo,
  setAssociateInfoFormData,
  updateAssociateInformation,
  getPlacementDetails,
  getAccommodationKeys,
  setIsEditAssociateInfoSaving,
  getAustinCasesByAlias,
  getAccommodationStatusForAccommodation,
} from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Icon from "@amzn/meridian/icon";
import searchTokens from "@amzn/meridian-tokens/base/icon/search";
import { COUNTRY_ID_MAPPING } from "src/config/CountryIdMapping";
import {
  DALI,
  BALI,
  AUSTIN,
} from "src/components/pages/newJMR/backgroundPage/associateInformation/caseSource/constant";
import BaliOrDaliOption from "src/components/pages/newJMR/backgroundPage/associateInformation/caseSource/baliOrDaliOption";
import CaseSelection from "src/components/shared/caseSelection/caseSelection";
import Link from "@amzn/meridian/link";
import { getExampleCaseIdText } from "src/utils/helpers";

const EditAssociateInfoModalAUSTIN = () => {
  const {
    isEditAssociateInfoModalOpen,
    editAssociateInfoModalFormData,
    placementId,
    isEditAssociateInfoSaving,
    placementDetails,
    austinCasesByAlias,
  } = useAppSelector((state) => state.placementDetailsPage);

  const {featureFlags} = useAppSelector(state => state.user);

  const dispatch = useAppDispatch();

  const [manuallyEnterCaseId, setManuallyEnterCaseId] = useState(false);

  useEffect(() => {
    if (isEditAssociateInfoModalOpen) {
      dispatch(getAustinCasesByAlias(editAssociateInfoModalFormData.login));
    }
  }, [isEditAssociateInfoModalOpen]);

  const handleChange = (key: string, val: string) => {
    dispatch(setAssociateInfoFormData({ ...editAssociateInfoModalFormData, [key]: val }));
  };

  const toggleManuallyEnterCaseId = () => {
    setManuallyEnterCaseId(!manuallyEnterCaseId);
  };

  const saveAndClose = async () => {
    dispatch(setIsEditAssociateInfoSaving(true));
    const updateRequest: any = await dispatch(updateAssociateInformation());
    if (updateRequest.payload !== undefined && updateRequest.payload.status === 200) {
      dispatch(getPlacementDetails(+placementId));
      if(featureFlags.DP_INTEGRATION_P0) {
        dispatch(getAccommodationStatusForAccommodation(+placementId));
      }
      dispatch(getAccommodationKeys(+placementId));
      dispatch(setIsEditAssociateInfoModalOpen(false));
    }
    dispatch(setIsEditAssociateInfoSaving(false));
  };

  const isAnyFormDataAbsent = () => {
    const { login, fullName, employeeId, job, homeShift, caseId, caseSource } = editAssociateInfoModalFormData;
    return [login, fullName, employeeId, job, homeShift, caseId, caseSource].some((field) => field?.trim() === "");
  };

  return (
    <Modal
      title="Edit Associate Information"
      open={isEditAssociateInfoModalOpen}
      onClose={() => dispatch(setIsEditAssociateInfoModalOpen(false))}
      width={"580px"}
      bodySpacingInset="500"
    >
      <Column spacing="400">
        <Text>
          You can edit the associate's information here. Once done, please click the confirm button to save these
          changes.
        </Text>
        {!isEditAssociateInfoSaving ? (
          <>
            <Row widths={["grid-3", "grid-8"]}>
              <Text tag="label" type="b300" color="primary">
                Login
              </Text>
              <Input
                type="text"
                placeholder="Associate's alias"
                value={editAssociateInfoModalFormData.login}
                onChange={(newLogin) => handleChange("login", newLogin)}
              >
                <div
                  className="associate-info-modal__login-search-icon"
                  onClick={() => {
                    dispatch(getEmployeeInfo(editAssociateInfoModalFormData.login));
                    dispatch(getAustinCasesByAlias(editAssociateInfoModalFormData.login));
                  }}
                >
                  <Icon tokens={searchTokens}></Icon>
                </div>
              </Input>
            </Row>
            <Row widths={["grid-3", "grid-8"]}>
              <Text tag="label" type="b300" color="primary">
                Full name
              </Text>
              <Input
                disabled={true}
                type="text"
                placeholder="Associate's full name"
                value={editAssociateInfoModalFormData.fullName}
                onChange={(newFullName) => handleChange("fullName", newFullName)}
              ></Input>
            </Row>
            <Row widths={["grid-3", "grid-8"]}>
              <Text tag="label" type="b300" color="primary">
                Employee ID
              </Text>
              <Input
                disabled={true}
                type="text"
                placeholder="Associate's employee ID"
                value={editAssociateInfoModalFormData.employeeId}
                onChange={(newEmployeeId) => handleChange("employeeId", newEmployeeId)}
              ></Input>
            </Row>
            <Row widths={["grid-3", "grid-8"]}>
              <Text tag="label" type="b300" color="primary">
                Job
              </Text>
              <Input
                disabled={true}
                type="text"
                placeholder="Associate's assigned job"
                value={editAssociateInfoModalFormData.job}
                onChange={(newJob) => handleChange("job", newJob)}
              ></Input>
            </Row>
            <Row widths={["grid-3", "grid-8"]}>
              <Text tag="label" type="b300" color="primary">
                Shift
              </Text>
              <Input
                type="text"
                placeholder="Shift Code (i.e. DCA-0730)"
                value={editAssociateInfoModalFormData.homeShift}
                onChange={(newShift) => handleChange("homeShift", newShift)}
              ></Input>
            </Row>
            <Row widths={["grid-3", "grid-8"]}>
              <Text tag="label" type="b300" color="primary">
                Case Source
              </Text>
              <ButtonGroup
                value={editAssociateInfoModalFormData.caseSource}
                onChange={(newCaseSource) => {
                  handleChange("caseSource", newCaseSource);
                }}
              >
                <ButtonOption value="AUSTIN">AUSTIN</ButtonOption>
                <BaliOrDaliOption
                  disabled={false}
                  baliOrDali={placementDetails.countryId === COUNTRY_ID_MAPPING.CANADA_COUNTRY_ID ? BALI : DALI}
                ></BaliOrDaliOption>
              </ButtonGroup>
            </Row>
            {(editAssociateInfoModalFormData.caseSource === DALI ||
              editAssociateInfoModalFormData.caseSource === BALI ||
              manuallyEnterCaseId) && (
              <Row widths={["grid-3", "grid-8"]}>
                <Text tag="label" type="b300" color="primary">
                  Case ID
                </Text>
                <Input
                  type="text"
                  placeholder={getExampleCaseIdText(editAssociateInfoModalFormData.caseSource)}
                  value={editAssociateInfoModalFormData.caseId}
                  onChange={(newCaseId) => handleChange("caseId", newCaseId)}
                ></Input>
              </Row>
            )}
            {editAssociateInfoModalFormData.caseSource === AUSTIN && (
              <Column>
                {!manuallyEnterCaseId && (
                  <Column>
                    <Row>
                      <Text>
                        Click <Link onClick={toggleManuallyEnterCaseId}>here</Link> to manually enter the Case ID
                      </Text>
                    </Row>
                    <Row>
                      <Text>AUSTIN cases associated with {editAssociateInfoModalFormData.fullName.split(" ")[0]}</Text>
                    </Row>
                    {austinCasesByAlias.map((austinCase) => (
                      <CaseSelection
                        austinCase={austinCase}
                        handleChange={() => handleChange("caseId", austinCase.caseId)}
                        selectedCaseId={editAssociateInfoModalFormData.caseId}
                        currentAccommodationId={placementId}
                      />
                    ))}
                  </Column>
                )}
                {manuallyEnterCaseId && (
                  <Row>
                    <Text>
                      Click <Link onClick={toggleManuallyEnterCaseId}>here</Link> to select from a list of cases.
                    </Text>
                  </Row>
                )}
              </Column>
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </Column>
      <ModalFooter>
        <Row alignmentHorizontal="end" widths="fit">
          <Button type="secondary" size="small" onClick={() => dispatch(setIsEditAssociateInfoModalOpen(false))}>
            Cancel
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={saveAndClose}
            disabled={isAnyFormDataAbsent() || isEditAssociateInfoSaving}
          >
            Confirm
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default EditAssociateInfoModalAUSTIN;
