import React, { useEffect } from "react";
import Text from "@amzn/meridian/text";
import "./updateWebHooks.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Checkbox from "@amzn/meridian/checkbox";
import Link from "@amzn/meridian/link";
import {
  getWebHookSettings,
  postWebHookSettings,
  setNotifyNewForNonWorkRelated,
  setNotifyNewForWorkRelated,
  setPageDisabled,
  setUrlForNonWorkRelated,
  setUrlForWorkRelated,
} from "src/store/updateWebHookSlice";
import Box from "@amzn/meridian/box";
import Heading from "@amzn/meridian/heading";
import LoadingSpinner from "src/components/shared/loadingSpinner/loadingSpinner";
import { WEBHOOKS_LINK } from "src/config/ExternalLinks";
import { COUNTRY_ID_MAPPING } from "src/config/CountryIdMapping";

const updateWebHooks = () => {
  const dispatch = useAppDispatch();
  const {
    urlForWorkRelated,
    urlForNonWorkRelated,
    notifyNewForWorkRelated,
    notifyNewForNonWorkRelated,
    pageDisabled,
    isLoading,
    urlInputLength,
  } = useAppSelector((state) => state.updateWebHookSlice);
  const { preferredSite } = useAppSelector((state) => state.user);
  const { userLoading } = useAppSelector((state) => state.user);
  const { selectedSite } = useAppSelector((state) => state.sites);

  useEffect(() => {
    if (userLoading === "fulfilled" && preferredSite !== 0) {
      dispatch(getWebHookSettings(preferredSite));
    }
  }, [userLoading, preferredSite]);

  const submit = () => {
    dispatch(postWebHookSettings(preferredSite));
    dispatch(setPageDisabled(!pageDisabled));
  };

  const toggleEdit = () => {
    dispatch(getWebHookSettings(preferredSite));
    dispatch(setPageDisabled(!pageDisabled));
  };

  // is the selected site a canadian site?
  const isSelectedSiteCanada = () => {
    return selectedSite?.countryId === COUNTRY_ID_MAPPING.CANADA_COUNTRY_ID;
  };

  return (
    <div className="update-webhooks">
      {!isLoading ? (
        <Column>
          <Heading level={1} type="h500">
            Update Webhooks
          </Heading>
          <Heading level={3}>Webhooks for {selectedSite?.site}</Heading>
          <Text>
            Here you update your site's chime or slack webhooks for
            accommodation notifications
          </Text>
          <Box type="outline" spacingInset="500 400">
            <Link href={WEBHOOKS_LINK}>Chime/Slack Webhook Wikilink</Link>
            <Row minHeight="75px">
              <Text>Chime / Slack Webhook URL for AUSTIN Placements:</Text>
              <Input
                value={urlForWorkRelated}
                disabled={pageDisabled}
                placeholder="Enter URL..."
                onChange={(newVal) => dispatch(setUrlForWorkRelated(newVal))}
                constraintText={`${urlForWorkRelated.length} / ${urlInputLength} characters`}
              />
              <Checkbox
                checked={notifyNewForWorkRelated}
                disabled={pageDisabled}
                onChange={(newVal) =>
                  dispatch(setNotifyNewForWorkRelated(newVal))
                }
              >
                Notify New Placements?
              </Checkbox>
            </Row>
            {!isSelectedSiteCanada() && (
              <Row
                minHeight="75px"
                className="site-management__updateWebHooks__dali"
              >
                <Text>Chime / Slack Webhook URL for DALI Placements:</Text>
                <Input
                  value={urlForNonWorkRelated}
                  disabled={pageDisabled}
                  placeholder="Enter URL..."
                  onChange={(newVal) =>
                    dispatch(setUrlForNonWorkRelated(newVal))
                  }
                  constraintText={`${urlForNonWorkRelated.length} / ${urlInputLength} characters`}
                />
                <Checkbox
                  checked={notifyNewForNonWorkRelated}
                  disabled={pageDisabled}
                  onChange={(newVal) =>
                    dispatch(setNotifyNewForNonWorkRelated(newVal))
                  }
                >
                  Notify New Placements?
                </Checkbox>
              </Row>
            )}
          </Box>
          {pageDisabled ? (
            <Row alignmentHorizontal="end">
              <Button
                id="site-management__update-webhooks__edit"
                minWidth={100}
                onClick={toggleEdit}
                type="primary"
              >
                Edit
              </Button>
            </Row>
          ) : (
            <Row alignmentHorizontal="end">
              <Button minWidth={100} onClick={toggleEdit} type="secondary">
                Cancel
              </Button>
              <Button
                id="site-management__update-webhooks__save"
                minWidth={100}
                onClick={submit}
                type="primary"
              >
                Save
              </Button>
            </Row>
          )}
        </Column>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default updateWebHooks;
