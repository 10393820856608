import Button from "@amzn/meridian/button";
import Modal from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import React, { useEffect } from "react";
import {
  downloadOOMD,
  setIsOomdModalOpen,
  setOomdFormData,
} from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import { OomdForm } from "src/store/placementDetailsPageSlice/types";
import { useAppDispatch, useAppSelector } from "src/store/store";
import InjuryInfo from "./injuryInfo/injuryInfo";

const twpaModal = () => {
  const { isOomdModalOpen, oomdFormData } = useAppSelector(
    (state) => state.placementDetailsPage
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setOomdFormData({}));
  }, [isOomdModalOpen]);

  const handleChange = (key: keyof OomdForm, value: any) => {
    dispatch(setOomdFormData({ ...oomdFormData, [key]: value }));
  };

  return (
    <Modal
      title="Offer of Modified Duties"
      open={isOomdModalOpen}
      onClose={() => dispatch(setIsOomdModalOpen(false))}
      width={"780px"}
    >
      <InjuryInfo handleChange={handleChange} />

      <Row widths={["fill", "244px"]}>
        <div />
        <Row>
          <Button onClick={() => dispatch(setIsOomdModalOpen(false))}>
            Cancel
          </Button>
          <Button onClick={() => dispatch(downloadOOMD())}>Generate PDF</Button>
        </Row>
      </Row>
    </Modal>
  );
};

export default twpaModal;
