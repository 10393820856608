import React, { useEffect } from "react";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import {
  getRegionTasksById,
  setIsCancelModalOpen,
  setPageProgress,
  setRestrictionsFormErrors,
  setSelectedTasks,
} from "src/store/newJmrPageSlice";
import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";
import RestrictionList from "./restrictionList/restrictionList";
import Divider from "@amzn/meridian/divider";
import { useAppDispatch, useAppSelector } from "src/store/store";
import _ from "lodash";

const restrictionsPage = () => {
  const dispatch = useAppDispatch();
  const { selectedTasks, restrictionsFormErrors, backgroundFormData } =
    useAppSelector((state) => state.newJmrPage);

  const validateRestrictions = () => {
    let isValid = true;
    let newErrors = { ...restrictionsFormErrors };
    let shiftLength = backgroundFormData.cannotWorkNormalShiftHrs
      ? backgroundFormData.limitedHoursPerDay
      : backgroundFormData.shiftLength;
    const newTasks = _.cloneDeep(selectedTasks);
    const invalidRestrictedValues = new Set(['-', '.', '-.']);
    for (let i = 0; i < selectedTasks.length; i++) {
      const task = selectedTasks[i];
      newTasks[i].isExpanded = true;

      if (task.measureUnit !== "YES/NO") {
        if (!task.restrictedValue) {
          isValid = false;
          newErrors[task.label] = "Restricted value is required";
        } else if (invalidRestrictedValues.has(task.restrictedValue)) {
          isValid = false;
          newErrors[task.label] = "Restricted value is invalid";
        } else if (+task.restrictedValue < 0 ) {
          isValid = false;
          newErrors[task.label] = "Restricted value cannot be negative";
        } else if (+task.restrictedValue > shiftLength) {
          isValid = false;
          newErrors[
            task.label
          ] = `Value must be smaller than ${shiftLength} hours`;
        } else {
          delete newErrors[task.label];
          newTasks[i].isExpanded = false;
        }
      }
    }
    dispatch(setSelectedTasks(newTasks));
    dispatch(setRestrictionsFormErrors(newErrors));
    return isValid;
  };

  useEffect(() => {
    dispatch(getRegionTasksById());
  }, []);

  return (
    <>
      <Text data-cy="new-jmr__title" type="d100" className="new-jmr__title">
        Physical Restrictions
      </Text>
      <Box maxWidth={"80%"}>
        <Column spacing={"200"}>
          <Text type="h300">Add and Edit Restriction(s)</Text>
          <Text type="b300" color="secondary">
            Please select all tasks denoted as restrictions within the
            associate's RFI. Once all tasks are selected, please input the
            restricted hours as noted in the RFI.
          </Text>
        </Column>
        <Divider spacingAfter={"400"} spacingBefore={"400"} />
      </Box>
      <RestrictionList />

      <Box maxWidth={"80%"}>
        <Row widths={["fill", "70px", "90px", "193px"]}>
          <div></div>
          <Button
            type="link"
            onClick={() => dispatch(setIsCancelModalOpen(true))}
          >
            Cancel
          </Button>
          <Button type="tertiary" onClick={() => dispatch(setPageProgress(0))}>
            Previous
          </Button>
          <Button
            data-cy="new-jmr__restrictions__next"
            onClick={() => {
              if (validateRestrictions()) {
                dispatch(setPageProgress(2));
              }
            }}
          >
            Generate Job Matches
          </Button>
        </Row>
      </Box>
    </>
  );
};

export default restrictionsPage;
