import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import Row from "@amzn/meridian/row";
import Icon from "@amzn/meridian/icon";
import Text from "@amzn/meridian/text";
import menuTokens from "@amzn/meridian-tokens/base/icon/menu";
import "./sortableRegionTaskRow.scss";
import { Task } from "src/store/regionTasksPageSlice";

const ROW_LAYOUT = ["grid-1", "grid-1", "grid-3", "grid-2", "grid-2", "grid-3"];
const SortableRegionTaskRow = (props: { task: Task }) => {
  const { task } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: task.taskId,
    animateLayoutChanges: () => false
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div className='region-task--sortable-row' style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <Row widths={ROW_LAYOUT} width='100%'>
        <Text>
          <Icon tokens={menuTokens} />
        </Text>
        <Text>{task.rank == null ? "Not Ranked" : task.rank}</Text>
        <Text>{task.label}</Text>
        <Text>{task.identifyBodyLocation ? "Yes" : "No"}</Text>
        <Text>{task.measureUnit || ""}</Text>
        <Text>{task.description || ""}</Text>
      </Row>
    </div>
  );
};

export default SortableRegionTaskRow;
